export default [
  {
    to: "/office",
    icon: "mdi-briefcase-clock",
    text: "ホーム",
    authority: [0, 1, 2, 3, 4, 5, 9]
  },
  {
    open: false,
    text: "ナビン-レイアウト",
    icon: "mdi-tune",
    authority: [1, 2, 3, 4, 5, 9],
    children: [
      {
        text: "パッケージ登録",
        icon: "mdi-tune",
        to: "/package-registration",
        authority: [1, 2, 3, 4, 5, 9]
      },
      {
        text: "見積内容登録",
        icon: "mdi-tune",
        to: "/quotation-content-registration",
        authority: [1, 2, 3, 4, 5, 9]
      },
      {
        text: "完了報告書",
        icon: "mdi-tune",
        to: "/completion-report",
        authority: [1, 2, 3, 4, 5, 9]
        // authority: [0, 1, 2, 3, 4, 5, 9]
      }
    ]
  },
  {
    open: false,
    text: "アマン-レイアウト",
    icon: "mdi-tune",
    authority: [1, 2, 3, 4, 5, 9],
    children: [
      {
        // text: "gamen1",
        text: "画面イメージ",
        icon: "mdi-tablet",
        to: "/scheduleMaint1",
        authority: [1, 2, 3, 4, 5, 9]
      },
      {
        text: "パッケージ登録",
        icon: "mdi-tablet",
        to: "/packageRegistration",
        authority: [1, 2, 3, 4, 5, 9]
      },
      {
        text: "見積内容登録",
        icon: "mdi-tablet",
        to: "/estimateContentRegistration",
        authority: [1, 2, 3, 4, 5, 9]
      }
    ]
  },
  {
    to: "/scheduleMaintenance",
    icon: "mdi-wrench-clock",
    text: "予定管理",
    authority: [2, 3, 4, 5, 9]
  },
  {
    to: "/servicerecordMaintenance",
    icon: "mdi-wrench-clock",
    text: "実績管理",
    authority: [1, 2, 3, 4, 5, 9]
  },
  {
    to: "/unfinished",
    icon: "mdi-wrench-clock",
    text: "作業未了一覧",
    authority: [1, 2, 3, 4, 5, 9]
  },
  {
    to: "/search",
    icon: "mdi-wrench-clock",
    text: "スケジュール検索",
    authority: [1, 2, 3, 4, 5, 9]
  },
  {
    to: "/list/shain",
    icon: "mdi-account-multiple",
    text: "ユーザーマスタ",
    authority: [0, 1, 2, 3, 4, 5, 9]
  },
  {
    to: "/list/hospital",
    icon: "mdi-hospital-building",
    text: "病院マスタ",
    authority: [4, 5, 9]
  },
  {
    to: "/list/package",
    icon: "mdi-tune",
    text: "パッケージ情報",
    authority: [4, 5, 9]
  },
  {
    to: "/list/code-master",
    icon: "mdi-database",
    text: "コードマスタ",
    authority: [4, 5, 9]
  },
  {
    open: false,
    text: "Excel出力",
    icon: "mdi-file-download",
    authority: [1, 2, 3, 4, 5, 9],
    children: [
      {
        text: "定期作業完了書",
        icon: "mdi-file-excel",
        to: "/excelteikisagyo",
        authority: [1, 2, 3, 4, 5, 9]
      },
      {
        text: "定期作業完了書（日報）",
        icon: "mdi-file-excel",
        to: "/excelteikisagyonippo",
        authority: [1, 2, 3, 4, 5, 9]
      },
      {
        text: "週間予定表",
        icon: "mdi-file-excel",
        to: "/excelsyukanyotei",
        authority: [1, 2, 3, 4, 5, 9]
      }
    ]
  },
  // {
  //   to: "/backupuoutput",
  //   icon: "mdi-database",
  //   text: "バックアップ",
  //   authority: [4, 5, 9]
  // },
  // {
  //   open: false,
  //   text: "システム管理",
  //   icon: "mdi-account-cowboy-hat",
  //   authority: [9],
  //   children: [
  //     {
  //       to: "/list/code-group",
  //       icon: "mdi-database",
  //       text: "コードグループ",
  //       authority: [9]
  //     },
  //     {
  //       text: "デバッグ",
  //       icon: "mdi-vuejs",
  //       to: "/debug",
  //       authority: [9]
  //     }
  //   ]
  // },
  /*********************************************/
  {
    text: "ログアウト",
    icon: "mdi-logout",
    to: "/login",
    authority: [0, 1, 2, 3, 4, 5, 9]
  }
  /*********************************************/
];
